import React, {useEffect, useState} from "react";

const SlidingText = ({ words }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
  
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % words.length);
      }, 3000); // Change word every 3 seconds
  
      return () => clearInterval(interval);
    }, [words]);
  
    return (
      <div style={{ position: 'relative', height: '1.3em', overflow: 'hidden' }}>
        {words.map((word, index) => (
          <div
            key={index}
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              transform: `translateY(${100 * (index - currentIndex)}%)`,
              transition: 'transform 0.5s ease-in-out',
            }}
          >
            {word}
          </div>
        ))}
      </div>
    );
  };

  export default SlidingText;