import React, { useState } from 'react';
import CryptoJS from 'crypto-js';
import { generateCv } from '../../auth/api';

const MyCvComponent = () => {
    const [isCVLoading, setIsCVLoading] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState(false);


    const currentDate = () => {
        const date = new Date();

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${day}${month}${year}${hours}${minutes}${seconds}`;
    };


    const handleDownloadCV = async (e) => {
        e.preventDefault();
        setIsCVLoading(true);
        const date = new Date();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const ciphertext = CryptoJS.MD5(minutes).toString();
        try {
            const requestData = {
                token: ciphertext,
                cover : 'none',
                secure : 1,
            };
            const response = await generateCv(requestData);
            if (response.size < 50000) {
                setSnackbarMessage(true);
                setIsCVLoading(false);
                return;
            }
            const blob = new Blob([response], { type: 'application/pdf' });
            await new Promise(resolve => setTimeout(resolve, 0));
            const url = URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            const dateStr = currentDate();
            a.download = `CV_of_Saklayen_Ahmed_${dateStr}.pdf`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error generating PDF:', error);
        } finally {
            setIsCVLoading(false);
        }
    };

    return (
        <>
            <button
                className='cv_download_button nav-anim btn btn-primary'
                onClick={handleDownloadCV}
                disabled={isCVLoading}
            >
                {snackbarMessage ? (isCVLoading ? 'Re-Generating CV' : 'CV Generating Error') : (isCVLoading ? 'Generating CV' : 'Download CV')}
            </button>
        </>
    );
};

export default MyCvComponent;
