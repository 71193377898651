import React, { useEffect, useState, useMemo } from 'react';
import { fetchData } from '../../auth/api';
import { CircularProgress, Box, Grid, Typography, Skeleton, Container, useMediaQuery, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useImageToBase64 from '../../tools/Base64Images';
import { getProdDevUrl } from '../../tools/commonFunction';
import MyCvComponent from '../sections/GenerateCV';

const HomeSection = () => {
  const [personal, setPersonal] = useState([]);
  const [index, setIndex] = useState(0);
  const [currentText, setCurrentText] = useState("");
  const [currentCharIndex, setCurrentCharIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(false);
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
  // const [isDesktop, setIsDeskTop] = useState(false);
  // eslint-disable-next-line
  const navigate = useNavigate();
  // const personalTags = personal && personal.tag ? JSON.parse(personal.tag) : [];
  const tables = ['personalinfo'];
  const personalTags = useMemo(() => {
    return personal && personal.tag ? JSON.parse(personal.tag) : [];
  }, [personal]);

  useEffect(() => {
    fetchData(tables)
      .then(responseData => {
        const getdata = responseData.saklayen;
        const personalinfo = getdata.personalinfo[0];
        setPersonal(personalinfo);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (personalTags.length > 0 && personalTags[index] && currentCharIndex <= personalTags[index].length) {
      const timeoutId = setTimeout(() => {
        setCurrentText(personalTags[index].substr(0, currentCharIndex + 1));
        setCurrentCharIndex(currentCharIndex + 1);
      }, 100);
      return () => clearTimeout(timeoutId);
    } else if (personalTags[index] && currentCharIndex > personalTags[index].length) {
      const timeoutId = setTimeout(() => {
        setIndex((index + 1) % personalTags.length);
        setCurrentCharIndex(0);
        setCurrentText("");
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [index, currentCharIndex, personalTags]);

  const imageUrl = `${getProdDevUrl()}/assets/img/` + personal.photo;
  const base64Image = useImageToBase64(imageUrl, 0, 400);

  return (
    <div className={isDesktop ? "section-content vcentered" : "section-content"}>
      {loading ? (
        <div style={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          height: isDesktop ? "" : "100vh",
          }}>
          <CircularProgress style={{ color: '#5e2fd6' }} />
        </div>
      ) : (
        <Container sx={{
          padding: "0"
        }}>
          <Grid container sx={{
            paddingBottom: isDesktop ? "75px" : "100px",
            display: "flex!important",
            alignItems: "center"
          }} className="home_page_style">
            {
              !isDesktop && (
                <Grid item xs={12} md={5} lg={5} className='image_grid'>
                  <div className="home_photo">
                    <div className="home_inner">
                      {!imageLoaded &&
                        <Skeleton animation="wave" className='skeletonProfileHome' variant="circular" />}
                      <img
                        src={base64Image}
                        alt={personal.name}
                        onLoad={() => setImageLoaded(true)}
                        style={imageLoaded ? {} : { display: 'none' }}
                      />
                    </div>
                  </div>
                  <div class="hp-dots"></div>
                </Grid>
              )
            }

            <Grid className='home_text' item xs={12} md={isDesktop ? 12 : 7} lg={isDesktop ? 12 : 7}>
              <div className="home-text">
                <div id="getTag" className="text-rotation">
                  <div className="item">
                    <div className="sp-subtitle">
                      <span className="typing-effect" style={{
                        color: "#aaa"
                      }}>{currentText}</span>
                    </div>
                  </div>
                </div>
                <Typography>
                  <h1 className='home_name'>{personal.name}</h1>
                </Typography>
                <Box sx={{ m: 3 }} />
                <Typography paragraph
                  sx={{
                    textAlign: "justify",
                    marginTop: "20px",
                    marginBottom: "30px",
                    fontFamily: "Poppins,Helvetica,sans-serif",
                    lineHeight: "1.7"
                  }}>
                  {personal.car_obj}
                </Typography>
                <div className="home-buttons">
                  {(personal.cv_download === 1 || personal.cv_download === '1') && (
                    <MyCvComponent />
                  )}
                  <button onClick={() => {
                    navigate("/contact")
                  }} className='cv_download_button nav-anim btn btn-secondary'>
                    Contact
                  </button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      )}
    </div>
  );
};

export default HomeSection;
