import React, { useEffect, useState, useRef } from 'react';
import {
    TextField,
    FormControl,
    Typography,
    Box,
    Stack,
} from '@mui/material';
import { useLocation } from 'react-router-dom';

import ReCAPTCHA from 'react-google-recaptcha';

import { fetchData, feedBack } from '../../auth/api';
import { CircularProgress, useMediaQuery } from '@mui/material';

import RandomBlockTitle from './FeedBackTitle';

function Feedback() {
    const tables = ['personalinfo'];
    const [personal, setPersonal] = useState([]);
    const [loading, setLoading] = useState(true);
    const [btnLabel, setBtnLabel] = useState('Give Feedback');
    const [message, setMessage] = useState('');
    const mainDataRef = useRef({});
    const TABLE_NAME = 'feedback';
    const isMobile = useMediaQuery('(max-width:600px)');
    const captchaRef = useRef(null);
    const [isCaptchaSuccessful, setIsCaptchaSuccess] = useState(false);
    const [captchaKey, setCaptchaKey] = useState('');


    const nameRef = useRef(null);
    const messageRef = useRef(null);

    const styles = {
        formStyle: {
            padding: '20px',
            borderRadius: '5px'
        },

        defaultMessageStyle: {
            padding: '10px',
            color: '#e74c3c'
        },
        successMessageStyle: {
            padding: '10px',
            color: 'green',
            fontWeight: '700'
        }
    };

    function toProperCase(text) {
        return text
            .toLowerCase()
            .split('-')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    const [messageStyle, setMessageStyle] = useState(styles.defaultMessageStyle);

    useEffect(() => {
        fetchData(tables)
            .then(responseData => {
                const getdata = responseData.saklayen;
                const personalinfo = getdata.personalinfo[0];
                setPersonal(personalinfo);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
        // eslint-disable-next-line
    }, []);

    const getCurrentDate = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        const hours = ('0' + date.getHours()).slice(-2);
        const minutes = ('0' + date.getMinutes()).slice(-2);
        const seconds = ('0' + date.getSeconds()).slice(-2);
        return `${month}-${day}-${year} ${hours}:${minutes}:${seconds}`;
    }


    const onRecaptchaChange = (value) => {
        setIsCaptchaSuccess(true)
        setCaptchaKey(value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const currentDate = getCurrentDate();
        setBtnLabel('Sending...');
        const requestData = {
            table: TABLE_NAME,
            data: {
                date_added: currentDate,
                name: nameRef.current.value,
                message: messageRef.current.value,
            },
            captcha: captchaKey,
            secret: process.env.REACT_APP_GOOGLE_SECRET_KEY,
        };

        feedBack(requestData)
            .then(response => {
                if (response.error) {
                    setBtnLabel('Send again');
                    setMessageStyle({ color: 'red', fontWeight: '700' });
                    setMessage(response.message);
                } else {
                    setBtnLabel('Send again');
                    setMessageStyle({ color: 'green', fontWeight: '700' });
                    setMessage(response.message);
                    nameRef.current.value = '';
                    messageRef.current.value = '';
                }
                captchaRef.current.reset();

            })
            .catch(error => {
                setBtnLabel('Try again');
                setMessageStyle({ color: 'red', fontWeight: '700' });
                setMessage('Sorry! Please try again later.');
                console.error(error);
            });
    }

    const location = useLocation();
    const path = location.pathname.split('/')[1];

    return (
        <div className="section-content main_body_wrapper">
            {loading ? (
                <div className='spinnerBar'>
                    <CircularProgress
                        style={{
                            color: '#5e2fd6'
                        }}
                    />
                </div>
            ) : (
                <><div className="page-title">
                    <h2>{toProperCase(path)}</h2>
                </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12">
                            <RandomBlockTitle />
                            <FormComponent
                                handleSubmit={handleSubmit}
                                messageStyle={messageStyle}
                                isCaptchaSuccessful={isCaptchaSuccessful}
                                captchaRef={captchaRef}
                                message={message}
                                isMobile={isMobile}
                                btnLabel={btnLabel}
                                nameRef={nameRef}
                                onRecaptchaChange={onRecaptchaChange}
                                mainDataRef={mainDataRef}
                                messageRef={messageRef}
                            />
                        </div>
                    </div>
                </>

            )}


        </div>
    )
}

function FormComponent({
    handleSubmit,
    messageStyle,
    message,
    btnLabel,
    onRecaptchaChange,
    nameRef,
    captchaRef,
    messageRef
}) {
    return (
        <Box
            component="form"
            className='form_contact'
            sx={{
                marginTop: '2rem',
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: '1rem',
            }}
        >

            <FormControl sx={{ gridColumn: 'span 2' }}>
                <TextField
                    id="message-textarea"
                    inputRef={messageRef}
                    defaultValue={messageRef.current ? messageRef.current.value : ''}
                    multiline
                    rows={15}
                    name='message'
                    label="Your Feedback"
                    placeholder="Provide your thoughts or impressions on Saklayen Ahmed."
                    required
                />
            </FormControl>

            <FormControl className='form_control' sx={{ gridColumn: 'span 2' }}>
                <TextField
                    inputRef={nameRef}
                    label="Name (Optional)"
                    name='name'
                    defaultValue={nameRef.current ? nameRef.current.value : ''}
                    variant="outlined"
                />
            </FormControl>

            <Stack sx={{ gridColumn: 'span 2' }} spacing={2} direction="column" style={{ marginTop: '20px' }} justifyContent="flex-end">
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
                    onChange={onRecaptchaChange}
                    ref={captchaRef}
                />

                {message && (
                    <Typography className='contactMessage' variant="body1" style={messageStyle}>
                        {message}
                    </Typography>
                )}
                <Box
                    display="flex"
                    alignContent="flex-start"
                    flexDirection="column"
                    flexWrap="wrap"
                    justifyContent="flex-start"
                >

                    <button
                        style={{
                            marginTop: '1rem'
                        }}
                        onClick={handleSubmit}
                        className='button btn-send'
                        disabled={btnLabel === 'Sending...'}
                    >
                        {btnLabel}
                    </button>
                </Box>
            </Stack>
        </Box>

    );

}

export default Feedback;
