import React, { useEffect, useState, useCallback, useRef } from 'react';
import { blogFetch, blogComment } from '../../auth/api'
import { CircularProgress, Dialog, Skeleton, Box, DialogTitle, Typography, Paper, FormControl, TextField, Stack, useMediaQuery } from '@mui/material';
import { getProdDevUrl, properCase, upperCase } from '../../tools/commonFunction';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import useImageToBase64 from './../../tools/Base64Images';
import ReCAPTCHA from 'react-google-recaptcha';

import { styled } from '@mui/system';

const StyledDialog = styled(Dialog)`
    .MuiPaper-root {
        border-radius: 25px !important;
    }
`;

const ShareButton = ({ platform, shareUrl, iconClass }) => {
    return (
        <a
            href="#"
            onClick={() => window.open(shareUrl, platform + '-share-popup', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600')}
            className="btn"
            target="_blank"
            title={`Share on ${platform}`}
        >
            <i className={iconClass}></i>
        </a>
    );
};

function Blog() {
    const [blogs, setBlog] = useState({});
    const [blogComments, setBlogComments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [imageLoaded, setImageLoaded] = useState(false);
    const captchaRef = useRef(null);
    const [isCaptchaSuccessful, setIsCaptchaSuccess] = useState(false);
    const [captchaKey, setCaptchaKey] = useState('');
    const TABLE_NAME = ['blogs', 'blog_filter', 'blog_comment'];
    const [btnLabel, setBtnLabel] = useState('Post Comment');
    const [message, setMessage] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:768px)');
    const blog_url = location.pathname.split('/')[2];
    const full_url_path = `${window.location.origin}${location.pathname}`;

    const nameRef = useRef(null);
    const mobileRef = useRef(null);
    const emailRef = useRef(null);
    const commentRef = useRef(null);
    const styles = {
        formStyle: {
            padding: '20px',
            borderRadius: '5px'
        },

        defaultMessageStyle: {
            padding: '10px',
            color: '#e74c3c'
        },
        successMessageStyle: {
            padding: '10px',
            color: 'green',
            fontWeight: '700'
        }
    };
    const [messageStyle, setMessageStyle] = useState(styles.defaultMessageStyle);

    useEffect(() => {
        blogFetch(blog_url)
            .then(responseData => {
                const getdata = responseData;
                if (getdata.status === 0 || getdata.status === '0') {
                    navigate("/blog", { replace: true });
                } else {
                    setBlog(getdata);
                    setBlogComments(Array.isArray(getdata.blog_comment) ? getdata.blog_comment : []);
                    setLoading(false);
                }

            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
        // eslint-disable-next-line
    }, [blog_url]);

    const onRecaptchaChange = (value) => {
        setIsCaptchaSuccess(true)
        setCaptchaKey(value);
    }

    const getCurrentDate = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        const hours = ('0' + date.getHours()).slice(-2);
        const minutes = ('0' + date.getMinutes()).slice(-2);
        const seconds = ('0' + date.getSeconds()).slice(-2);
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    // console.log(blogComments);

    const handleSubmit = (e) => {
        e.preventDefault();
        const currentDate = getCurrentDate();
        setBtnLabel('Posting...');
        const newComment = {
            blog_id: blogs.id,
            comments: commentRef.current.value,
            name: nameRef.current.value,
            mobile: mobileRef.current.value,
            email: emailRef.current.value,
            date_added: currentDate,
            visibility: 1
        };

        const requestData = {
            table: TABLE_NAME[2],
            data: newComment,
            captcha: captchaKey,
            secret: process.env.REACT_APP_GOOGLE_SECRET_KEY,
        };

        blogComment(requestData)
            .then(response => {
                if (response.error) {
                    setBtnLabel('Try again');
                    setMessageStyle({ color: 'red', fontWeight: '700' });
                    setMessage(response.message);
                } else {
                    setBtnLabel('Post New Comments');
                    setMessageStyle({ color: 'green', fontWeight: '700' });
                    setMessage(response.message);
                    setBlogComments(prevComments => {
                        const currentComments = Array.isArray(prevComments) ? prevComments : [];
                        return [...currentComments, newComment];
                    });
                    nameRef.current.value = commentRef.current.value = emailRef.current.value = mobileRef.current.value = '';
                }
                captchaRef.current.reset();

            })
            .catch(error => {
                setBtnLabel('Try again');
                setMessageStyle({ color: 'red', fontWeight: '700' });
                setMessage('Sorry! Please try again later.');
                console.error(error);
            });
    }

    const platforms = [
        { platform: 'linkedin', shareUrl: `https://www.linkedin.com/shareArticle?mini=true&url=${full_url_path}`, iconClass: 'fab fa-linkedin-in' },
        { platform: 'facebook', shareUrl: `https://www.facebook.com/sharer/sharer.php?u=${full_url_path}`, iconClass: 'fab fa-facebook-f' },
        { platform: 'whatsapp', shareUrl: `whatsapp://send?text=${full_url_path}`, iconClass: 'fab fa-whatsapp' },
        { platform: 'twitter', shareUrl: `https://twitter.com/intent/tweet?text=${full_url_path}`, iconClass: 'fab fa-twitter' },
    ];

    const imageUrl = `${getProdDevUrl()}/assets/img/` + blogs.image;
    const base64Image = useImageToBase64(imageUrl, 0, 500);

    // console.log(blogs);

    const truncateDescription = (text, maxLength) => {
        if (text.length <= maxLength) return text;
        return text.substr(0, maxLength - 3) + '...';
    };

    return (
        <>
            <div className="section-content main_body_wrapper">
                {loading ? (
                    <div className='spinnerBar'>
                        <CircularProgress
                            style={{
                                color: '#5e2fd6'
                            }}
                        />
                    </div>
                ) : (
                    <>
                        <Helmet>
                            <title>{blogs.title} | Saklayen Ahmed</title>
                            <meta name="description" content={blogs.description ? truncateDescription(blogs.description.replace(/<[^>]+>/g, ''), 160) : ''} />
                            <meta property="og:title" content={blogs.title} />
                            <meta property="og:description" content={blogs.description ? truncateDescription(blogs.description.replace(/<[^>]+>/g, ''), 160) : ''} />
                            <meta property="og:image" content={`${getProdDevUrl()}/assets/img/${blogs.image}`} />
                            <meta property="og:url" content={full_url_path} />
                            <meta name="twitter:card" content="summary_large_image" />
                            <meta name="twitter:title" content={blogs.title} />
                            <meta name="twitter:description" content={blogs.description ? truncateDescription(blogs.description.replace(/<[^>]+>/g, ''), 160) : ''} />
                            <meta name="twitter:image" content={`${getProdDevUrl()}/assets/img/${blogs.image}`} />
                        </Helmet>

                        <article className="post">

                            <div className="post-content">
                                <header className="entry-header">
                                    <div className="entry-meta entry-meta-top">
                                        <span><a href="#" rel="category tag">{blogs.category}</a></span>
                                    </div>
                                    <h2 className="entry-title">{blogs.title}</h2>
                                </header>
                                {base64Image === null ? "" : (
                                    <>
                                        <div className="postSpinner">
                                            {!imageLoaded && <CircularProgress
                                                style={{
                                                    color: '#5e2fd6'
                                                }} />}
                                        </div>
                                        <div className="post-thumbnail">
                                            <img
                                                alt={blogs.title}
                                                src={base64Image}
                                                onLoad={() => setImageLoaded(true)}
                                                style={imageLoaded ? {} : { display: 'none' }}
                                            />
                                        </div>
                                    </>
                                )}
                                <div className="entry-content">
                                    <div className="row">
                                        <div className=" col-xs-12 col-sm-12 ">
                                            <div className="col-inner">
                                                <Typography
                                                    className='blog_body_text'
                                                    variant="body1"
                                                    dangerouslySetInnerHTML={{ __html: blogs.description }}
                                                    style={{
                                                        fontFamily: 'Poppins,Helvetica,sans-serif',
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="entry-meta entry-meta-bottom">
                                    <div className="date-author">
                                        <span className="entry-date">
                                            <a href="#" rel="bookmark">
                                                <i className="fas fa-clock"></i>
                                                <span className="entry-date">&nbsp;{blogs.date_added}</span>
                                            </a>
                                        </span>
                                        <span className="author vcard">
                                            <a className="url fn n" href="#" rel="author">
                                                <i className="fas fa-user-alt"></i>&nbsp;{blogs.added_by}
                                            </a>
                                        </span>
                                    </div>

                                    <div className="entry-share btn-group share-buttons">
                                        {platforms.map((platform) => (
                                            <ShareButton key={platform.platform} {...platform} />
                                        ))}
                                    </div>
                                </div>
                                <div className='row btn_for_prev_next'>
                                    <div className='col-md-6 btn_for_prev'
                                        onClick={() => {
                                            navigate("/blog/" + blogs.prev.title_url)
                                            setLoading(true);

                                        }} >
                                        <div className="col_btn_prev">
                                            <div className="prev"> <i className='fas fa-chevron-left'></i> Previous</div>
                                            <div className="blog_cat">{blogs.prev.category}</div>
                                            <h4 className="blog_title">{blogs.prev.title}</h4>
                                        </div>
                                    </div>
                                    <div className='col-md-6 btn_for_next'
                                        onClick={() => {
                                            navigate("/blog/" + blogs.next.title_url)
                                            setLoading(true);

                                        }} >
                                        <div className="col_btn_next">
                                            <div className="next">Next <i className='fas fa-chevron-right'></i> </div>
                                            <div className="blog_cat">{blogs.next.category}</div>
                                            <h4 className="blog_title">{blogs.next.title}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div class="block-title comment_title">
                                    <h3>
                                        Comments  {blogComments !== undefined ? "(" + blogComments.length + ")" : "(0)"}
                                    </h3>
                                </div>
                                <div className="row user_comment_data">
                                    {Array.isArray(blogComments) && blogComments.length > 0 ? (
                                        blogComments.map(item => (
                                            <div key={item.id} className="col_user col-md-12">
                                                <div className='user_name_date'>
                                                    <span className='user_name'>
                                                        {item.name}
                                                    </span>
                                                    <time className='cmnt_date'>
                                                        {item.date_added}
                                                    </time></div>
                                                <div className='user_comments'>{item.comments}</div>
                                            </div>
                                        ))
                                    ) : (
                                        <Typography className='no_comments'>No comments found for this post.</Typography>
                                    )}
                                </div>


                                <div className="comments-area">
                                    <div className="comment-respond">
                                        <h3 className="comment-reply-title">Leave a Comment</h3>
                                        <Box
                                            component="form"
                                            className="form_contact"
                                            sx={{
                                                marginTop: '2rem',
                                                display: 'grid',
                                                gridTemplateColumns: '1fr 1fr',
                                                gap: '1rem',
                                            }}>
                                            <FormControl sx={{ gridColumn: 'span 2' }}>
                                                <TextField
                                                    id="message-textarea"
                                                    inputRef={commentRef}
                                                    defaultValue={commentRef.current ? commentRef.current.value : ''}
                                                    multiline
                                                    rows={10}
                                                    name='comments'
                                                    label="Your Comments"
                                                    placeholder="Provide your thoughts or impressions about this post."
                                                    required
                                                />
                                            </FormControl>

                                            <FormControl className='form_control' sx={{ gridColumn: 'span 2' }}>
                                                <TextField
                                                    inputRef={nameRef}
                                                    label="Name"
                                                    name='name'
                                                    placeholder='Enter your name'
                                                    defaultValue={nameRef.current ? nameRef.current.value : ''}
                                                    variant="outlined"
                                                    required
                                                />
                                            </FormControl>
                                            
                                            <FormControl className='form_control' sx={{ gridColumn: isMobile ? 'span 2' : '' }}>
                                                <TextField
                                                    inputRef={emailRef}
                                                    label="Email"
                                                    name='email'
                                                    placeholder='Enter your valid email'
                                                    defaultValue={emailRef.current ? emailRef.current.value : ''}
                                                    variant="outlined"
                                                    required
                                                />
                                            </FormControl>

                                            <FormControl className='form_control' sx={{ gridColumn: isMobile ? 'span 2' : '' }}>
                                                <TextField
                                                    inputRef={mobileRef}
                                                    label="Mobile (Optional)"
                                                    name='mobile'
                                                    placeholder='Enter your mobile number'
                                                    defaultValue={mobileRef.current ? mobileRef.current.value : ''}
                                                    variant="outlined"
                                                />
                                            </FormControl>

                                            <Stack sx={{ gridColumn: 'span 2' }} spacing={2} direction="column" style={{ marginTop: '20px' }} justifyContent="flex-end">
                                                <ReCAPTCHA
                                                    sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
                                                    onChange={onRecaptchaChange}
                                                    ref={captchaRef}
                                                />

                                                {message && (
                                                    <Typography className='contactMessage' variant="body1" style={messageStyle}>
                                                        {message}
                                                    </Typography>
                                                )}
                                                <Box
                                                    display="flex"
                                                    alignContent="flex-start"
                                                    flexDirection="column"
                                                    flexWrap="wrap"
                                                    justifyContent="flex-start"
                                                >
                                                    <button
                                                        style={{
                                                            marginTop: '1rem'
                                                        }}
                                                        onClick={handleSubmit}
                                                        className='button btn-send'
                                                        disabled={btnLabel === 'Posting...'}
                                                    >
                                                        {btnLabel}
                                                    </button>
                                                </Box>
                                            </Stack>
                                        </Box>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </>
                )}
            </div>
        </>

    );
}
export default Blog;

