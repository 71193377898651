import React from 'react';

class RandomBlockTitle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            blockTitles: [
                "Could you provide your <span>thoughts</span> or impressions on <span>Saklayen Ahmed</span>?",
                "Can you share your <span>views</span> or impressions of <span>Saklayen Ahmed</span>?",
                "Can you give your assessment or <span>thoughts</span> on <span>Saklayen Ahmed</span>?",
                "Could you please provide your <span>assessment</span> or analysis of <span>Saklayen Ahmed</span>?"
            ],
            randomIndex: 0
        };
    }

    componentDidMount() {
        const randomIndex = Math.floor(Math.random() * this.state.blockTitles.length);
        this.setState({ randomIndex });
    }

    render() {
        return (
            <div className="block-title">
                <h3 dangerouslySetInnerHTML={{__html: this.state.blockTitles[this.state.randomIndex]}}></h3>
            </div>
        );
    }
}

export default RandomBlockTitle;
