import React, { useEffect, useState } from 'react';
import { fetchData } from '../../auth/api'
import { CircularProgress, Dialog, DialogContent, Box, DialogTitle, Typography, Paper } from '@mui/material';
import { getProdDevUrl, properCase, upperCase } from '../../tools/commonFunction';

import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';

const StyledDialog = styled(Dialog)`
    .MuiPaper-root {
        border-radius: 25px !important;
    }
`;

function Blog() {
    const [blogs, setBlog] = useState([]);
    const [blogsOriginal, setBlogOriginal] = useState([]);
    const [blogFilters, setBlogFilter] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openBlog, setOpenBlog] = useState(false);
    const tables = ['blogs', 'blog_filter'];
    const [mainData, setMainData] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        fetchData(tables)
            .then(responseData => {
                const getdata = responseData.saklayen;
                const blogs = getdata.blogs;
                const blogOrg = getdata.blogs;
                const blogFilter = getdata.blog_filter;
                setBlog(blogs);
                setBlogOriginal(blogOrg);
                setBlogFilter(blogFilter);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
        // eslint-disable-next-line
    }, []);


    const handleFilter = (itemValue) => {
        const filteredResult = blogsOriginal.filter((item) => {
            return item.category.toLowerCase().includes(itemValue);
        });
        setBlog(filteredResult);
    };

    const navigatePage = (url) => {
        navigate(url, { replace: true });
      }

    const handleResetFilter = () => {
        setBlog(blogsOriginal);
    };
    const handleClose = () => {
        setOpenBlog(false)
    };

    const renderDialog = () => {
        return (
            <StyledDialog open={openBlog} onClose={handleClose} fullWidth maxWidth="md">
                <DialogTitle
                    sx={{
                        fontFamily: 'Poppins,Helvetica,sans-serif',
                        borderBottom: '1px solid #ddd'
                    }}>{mainData.title}</DialogTitle>
                <DialogContent>
                    <Typography
                        className='blog_body_text'
                        variant="body1"
                        dangerouslySetInnerHTML={{ __html: mainData.description }}
                        style={{
                            fontFamily: 'Poppins,Helvetica,sans-serif',
                            padding: '20px'
                        }}
                    />
                </DialogContent>
            </StyledDialog>
        );
    };


    function FilterList({ filterData }) {
        return (
            <ul id="filterList">
                <li className='itemFilterPort' onClick={() => {
                    handleResetFilter();
                }}>
                    <span href='#' className="filter btn btn-sm btn-link active">All</span>
                </li>
                {filterData.sort((b, a) => a.rank - b.rank).map(item => (
                    <li className='itemFilterPort' key={item.id}>
                        <span
                            onClick={() => {
                                handleFilter(item.category);
                            }}
                            className="filter btn btn-sm btn-link">
                            {item.text}
                        </span>
                    </li>
                ))}
            </ul>
        );
    }


    return (
        <div className="section-content main_body_wrapper">
            {loading ? (
                <div className='spinnerBar'>
                    <CircularProgress
                        style={{
                            color: '#5e2fd6'
                        }}
                    />
                </div>
            ) : (
                <>
                    <div className="page-title">
                        <h2>Blog</h2>
                    </div>

                    <div className="row">
                        <div className="col-xs-12 col-sm-12">
                            {blogs.length > 0 ? (
                                <>
                                    <ul className="portfolio-filters" id="portfolioFilter">
                                        <FilterList filterData={blogFilters} />
                                    </ul>
                                    <div className="blog-masonry two-columns clearfix">
                                        {blogs.sort((b, a) => a.rank - b.rank).map(item => (
                                            <div key={item.id} className="item post-1"
                                                onClick={() => {
                                                    setMainData(item);
                                                    // setOpenBlog(true)
                                                    navigatePage("/blog/"+item.title_url);
                                                }} >
                                                <div className="blog-card">
                                                    <div className="media-block" style={{
                                                        height: '200px',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        borderTop: '1px solid #ddd',
                                                        borderLeft: '1px solid #ddd',
                                                        borderRight: '1px solid #ddd'
                                                    }}>
                                                        <div className="category">
                                                            <a href="#" title={properCase(item.category)}>{properCase(item.category)}</a>
                                                        </div>
                                                        {/* <a href="#"> */}
                                                            <img src={`${getProdDevUrl()}/assets/img/${item.image}`} className="size-blog-masonry-image-two-c"
                                                                alt={item.title} title={item.title} />
                                                            <div className="mask"></div>
                                                        {/* </a> */}
                                                    </div>
                                                    <div className="post-info">
                                                        <div className="post-date">{item.date_added}</div>
                                                        {/* <a href="#"> */}
                                                            <h4 className="blog-item-title">{item.title}</h4>
                                                        {/* </a> */}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            ) : (
                                <div className='not_found_blog'>
                                    <span class="lnr lnr-book"></span>
                                    <div className='no_title'>
                                        <span>Oops! It seems like our tech blogs are on a short break, probably chatting with computer chips. Don't worry, they're just fixing up their jokes. Come back later for some fun tech stories and easy-to-understand computer giggles!</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </>

            )}
            {/* {renderDialog()} */}

        </div>
    );
}
export default Blog;

